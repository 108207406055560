import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import Meta from 'react-meta-tags';
import { useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Affix, Button, Card, Tabs, Typography } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { hasError } from '@util/StateHandler';
import { Colors } from '@util/color/Colors';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { CreatePost } from '@components/content/posts/creation_box/CreationBox';
import { CommunityDetails } from './components/community_details/CommunityDetails';
import { CommunityRules } from './components/community_rules/CommunityRules';
import { Router } from '@Router';
import { CommunityScreenSkeleton } from '@components/skeletons/CommunityScreenSkeleton';
import { AllMembersCard } from './components/members_card/AllMembersCard';
import { CommunityHeader } from '@components/content/entity/community_header/CommunityHeader';
import { CompleteProfile } from '@components/content/entity/complete_profile/CompleteProfile';
import { communityEventList, communityPosts } from '@state/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkBlockedScroll } from '@hooks/useBlockScroll';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton';
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed';
import { ListPost } from '@components/content/posts/list_post/ListPosts';
import { addWebp } from '@util/browser/addWebp';
import { EventDetails } from './event/EventDetails';
import { CalendarOutlined, PushpinOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { PostModal } from '@components/content/posts/post_modal/PostCreateProvider';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
import Paragraph from 'antd/lib/typography/Paragraph';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { FooterModal } from '@components/modals/components/FooterModal';
const { TabPane } = Tabs;
export const CommunityHome = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    const { cm_pk } = useParams();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const posts = useAppSelector((state) => { var _a; return (_a = state.posts.all['feed']) === null || _a === void 0 ? void 0 : _a.items; });
    const postState = useAppSelector((state) => { var _a; return (_a = state.posts.all['feed']) === null || _a === void 0 ? void 0 : _a.listState; });
    const notifications = useContext(NotificationContext);
    const location = useLocation();
    const [postType, setPostType] = useState('basic');
    const [postModal, setPostModal] = useState(false);
    const [postStep, setPostStep] = useState('creation');
    const entitiesNotifications = useAppSelector((state) => {
        if (state.notification.entities[cm_pk]) {
            return state.notification.entities[cm_pk].aggregate.newly;
        }
        else {
            return 0;
        }
    });
    const breakpoints = useBreakpoints();
    const history = useHistory();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    const [newPosts, setNewPosts] = useState(false);
    const dispatch = useAppDispatch();
    const events = useAppSelector((state) => { var _a; return (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.events; });
    const isAdmin = (_b = (_a = community === null || community === void 0 ? void 0 : community.item) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.includes('edit');
    const hasPostToRead = ((_d = (_c = community === null || community === void 0 ? void 0 : community.item) === null || _c === void 0 ? void 0 : _c.pinneds) === null || _d === void 0 ? void 0 : _d.readMandatory) - ((_f = (_e = community === null || community === void 0 ? void 0 : community.item) === null || _e === void 0 ? void 0 : _e.pinneds) === null || _f === void 0 ? void 0 : _f.read);
    if (cm_pk && cm_pk !== 'turingo' && !isLogged) {
        return _jsx(Redirect, { to: { pathname: Router.Path.community_login({ cm_pk }) } });
    }
    useEffect(() => {
        void dispatch(communityPosts({
            urlParams: {
                cm_pk,
            },
            extraParams: {
                section: 'feed',
            },
            headerParams: {
                page: 'first',
            },
        }));
        void dispatch(communityEventList({
            urlParams: { cm_pk },
        }));
    }, [cm_pk]);
    if (!community || ((_g = community.state) === null || _g === void 0 ? void 0 : _g.loading) || !postState || posts.loading) {
        return _jsx(CommunityScreenSkeleton, {});
    }
    else if (hasError([community.state, posts])) {
        return _jsx(CommunityScreenSkeleton, {});
    }
    else {
        const isPrivate = !((_h = community.item) === null || _h === void 0 ? void 0 : _h.access);
        const newPostComponent = (_jsx("div", { style: { position: 'absolute', top: 96, zIndex: 9, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center' }, children: _jsx(Affix, { offsetTop: 96, children: _jsx(Button, { onClick: () => {
                    }, style: { background: 'var(--background)', boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)' }, children: strings.screens.boards.discussion.details.new }) }) }));
        const firstScreen = (_jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24, position: 'relative' }, children: [_jsx(CommunityHeader, { isHome: true, showAvatar: true, entity: community }), isLogged && _jsx(CreatePost, { setPostModal: setPostModal, setPostStep: setPostStep, setPostType: setPostType }), hasPostToRead > 0 && (_jsx(Card, { bordered: false, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: isMobile ? 16 : 0 }, children: [_jsx("div", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '100px',
                                            backgroundColor: 'var(--ant-primary-1)',
                                            height: 40,
                                            width: 40,
                                        }, children: _jsx(PushpinOutlined, { style: { fontSize: 24, color: 'var(--ant-primary-color)' } }) }), _jsxs(Paragraph, { strong: true, style: { marginLeft: 8, marginRight: 8 }, children: [hasPostToRead == 1 && strings.screens.markasread.banner.singular, ' ', hasPostToRead > 1 && strings.formatString(strings.screens.markasread.banner.plural, hasPostToRead)] })] }), _jsx(LinkButton, { block: true, type: "primary", href: Router.Path.community_highlights({ cm_pk }), children: strings.screens.markasread.banner.action })] }) })), _jsx(InfiniteScroll, { dataLength: Object.keys(posts).length, next: () => {
                        if (!checkBlockedScroll()) {
                            if (!isLogged) {
                                dispatch(modalAction({ modal: 'see_more', state: true }));
                            }
                            else {
                                void dispatch(communityPosts(Object.assign({ urlParams: {
                                        cm_pk,
                                    }, headerParams: {
                                        page: postState === null || postState === void 0 ? void 0 : postState.pagination,
                                    }, extraParams: {
                                        section: 'feed',
                                    } }, ((postState === null || postState === void 0 ? void 0 : postState.topic) && {
                                    searchParams: {
                                        topic: postState === null || postState === void 0 ? void 0 : postState.topic,
                                    },
                                }))));
                            }
                        }
                    }, hasMore: (postState.pagination && postState.pagination != 'first') || postState.loading, loader: !isLogged ? null : _jsx(PostSkeleton, {}), endMessage: _jsx(Card, { style: { marginTop: 16 }, children: _jsx(AlertDashed, { showIcon: false, message: strings.screens.boards.discussion.details.no_more }) }), children: _jsx(ListPost, { items: posts }) })] }));
        const isCompleteProfile = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.avatarUrl) && ((_j = currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.profile) === null || _j === void 0 ? void 0 : _j.job);
        return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName((_k = community.item) === null || _k === void 0 ? void 0 : _k.name, notifications) }), _jsx("meta", { name: "description", content: (_l = community.item) === null || _l === void 0 ? void 0 : _l.description })] }), ((_m = location === null || location === void 0 ? void 0 : location.state) === null || _m === void 0 ? void 0 : _m.welcome) && (_jsx(TuringoModal, { width: 572, open: true, onCancel: () => {
                        history.push(Router.Path.community_home({ cm_pk }), {
                            welcome: false,
                        });
                    }, footer: [
                        _jsx(FooterModal, { primary: {
                                action: () => {
                                    history.push(Router.Path.community_home({ cm_pk }), {
                                        welcome: false,
                                    }),
                                        setPostModal(true);
                                },
                                customText: strings.modals.welcome.post,
                            }, secondary: {
                                action: () => {
                                    history.push(Router.Path.community_home({ cm_pk }), {
                                        welcome: false,
                                    });
                                },
                                customText: strings.modals.welcome.later,
                            } }, 'footer_modal'),
                    ], children: _jsx(InformationModalBody, { kind: 'welcome', title: strings.formatString(strings.modals.welcome.title, currentEntity.name), description: strings.modals.welcome.description }) })), postModal && (_jsx(PostModal, { item: {
                        kind: postType,
                    }, initialScreen: postStep, open: postModal, setVisible: setPostModal })), isMobile && (_jsxs("div", { children: [newPosts && isLogged && newPostComponent, isLogged && !isCompleteProfile && _jsx(CompleteProfile, {}), _jsxs(Tabs, { className: "with-padding", tabBarStyle: {
                                padding: breakpoints.isSmall ? '0 16px' : '0 24px',
                                background: Colors('BACKGROUND'),
                                marginBottom: 0,
                                borderRadius: breakpoints.isSmall ? 0 : '4px 4px 0px 0px',
                            }, defaultActiveKey: "1", children: [_jsx(TabPane, { tab: strings.general.community.singular, children: firstScreen }, "1"), _jsx(TabPane, { tab: strings.general.about, disabled: !isLogged, children: _jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24 }, children: [_jsx(CommunityHeader, { isHome: true, showAvatar: true, entity: community }), _jsx(CommunityDetails, { memberCount: (_o = community.item) === null || _o === void 0 ? void 0 : _o.memberCount, description: (_q = (_p = community.item) === null || _p === void 0 ? void 0 : _p.profile) === null || _q === void 0 ? void 0 : _q.bio, contact: (_r = community.item) === null || _r === void 0 ? void 0 : _r.contact }), ((_s = community.item) === null || _s === void 0 ? void 0 : _s.rule) && _jsx(CommunityRules, { isCompact: false, rules: (_t = community.item) === null || _t === void 0 ? void 0 : _t.rule })] }) }, "2"), isPrivate && (_jsx(TabPane, { tab: strings.screens.boards.discussion.subscribers, children: _jsxs("div", { style: { display: 'grid', rowGap: isMobile ? 16 : 24 }, children: [_jsx(CommunityHeader, { isHome: true, showAvatar: true, entity: community }), _jsx(AllMembersCard, {})] }) }, "3"))] })] })), !isMobile && (_jsx(ContentLayout, { left: _jsxs(_Fragment, { children: [newPosts && isLogged && newPostComponent, firstScreen] }), right: _jsxs("div", { style: {
                            display: 'grid',
                            columnGap: isSmall ? 0 : 16,
                            rowGap: isMobile ? 16 : 24,
                        }, children: [isLogged && !isCompleteProfile && _jsx(CompleteProfile, {}), _jsx(CommunityDetails, { memberCount: (_u = community.item) === null || _u === void 0 ? void 0 : _u.memberCount, description: (_w = (_v = community.item) === null || _v === void 0 ? void 0 : _v.profile) === null || _w === void 0 ? void 0 : _w.bio, contact: (_x = community.item) === null || _x === void 0 ? void 0 : _x.contact }), _jsxs(Card, { title: strings.screens.events.upcomingEvents, bordered: false, children: [(events === null || events === void 0 ? void 0 : events.items) &&
                                        Object.keys(events.items).map((key, index) => {
                                            var _a, _b, _c, _d;
                                            if (index > 2)
                                                return;
                                            if (!((_b = (_a = events.items[key]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.publicKey))
                                                return;
                                            return (_jsx(Link, { to: Router.Path.event_details({ cm_pk, e_pk: (_d = (_c = events.items[key]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.publicKey }), children: _jsxs(Card, { hoverable: true, bodyStyle: { padding: 0 }, style: { marginBottom: 24 }, children: [_jsxs("div", { style: { paddingTop: '40%', position: 'relative' }, children: [events.items[key].item.bannerUrl && (_jsx("img", { alt: "example", style: {
                                                                        objectFit: 'cover',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        borderBottom: '1px solid var(--border)',
                                                                    }, src: addWebp(events.items[key].item.bannerUrl), loading: "lazy" })), !events.items[key].item.bannerUrl && (_jsx("div", { style: {
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        backgroundColor: 'var(---image-empty-background)',
                                                                        borderBottom: '1px solid var(--border)',
                                                                    }, children: _jsx(CalendarOutlined, { style: { fontSize: 56, alignSelf: 'center', color: 'var(--neutral-6)' } }) }))] }), _jsxs("div", { style: { padding: 16 }, children: [_jsx(Typography.Paragraph, { style: { marginBottom: 16 }, strong: true, children: events.items[key].item.title }), _jsx(EventDetails, { item: events.items[key].item })] })] }) }, key));
                                        }), (events === null || events === void 0 ? void 0 : events.items) && Object.keys(events.items).length == 0 && (_jsx(AlertDashed, { showIcon: false, message: strings.screens.events.empty })), (events === null || events === void 0 ? void 0 : events.items) && Object.keys(events.items).length != 0 && (_jsx(Button, { block: true, onClick: () => history.push(Router.Path.event_list({ cm_pk })), children: strings.screens.community.goToEvents }))] }), ' ', ((_y = community.item) === null || _y === void 0 ? void 0 : _y.rule) && _jsx(CommunityRules, { isCompact: false, rules: (_z = community.item) === null || _z === void 0 ? void 0 : _z.rule })] }) }))] }));
    }
};

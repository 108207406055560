var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { API } from '../API';
import { message } from 'antd';
export const useRequestState = (initialData = undefined, initialFetchState = true, initialEditingState = false) => useState({ data: initialData, loading: initialFetchState, editing: initialEditingState });
export const getRequest = (request, setter, dependences) => {
    return (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (params = {}) {
        useEffect(() => {
            setter((prevState) => {
                return Object.assign(Object.assign({}, prevState), { loading: true, error: undefined });
            });
            request(params)
                .then((response) => {
                if (response.data.error) {
                    throw new Error(response.data.error);
                }
                setter({
                    data: response.data,
                    loading: false,
                    editing: false,
                    error: undefined,
                });
            })
                .catch((error) => {
                setter((prevState) => {
                    return Object.assign(Object.assign({}, prevState), { loading: false, editing: false, error: error.message });
                });
            });
        }, dependences ? dependences : []);
    });
};
function makeRequest(method, url, data) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            }
            else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText,
            });
        };
        xhr.send(data);
    });
}
const asyncUpload = (upload) => __awaiter(void 0, void 0, void 0, function* () {
    const URLPresigned = yield API.upload({
        bodyParams: {
            scope: upload.scope,
            mimetype: upload.item.type,
            size: upload.item.size,
            name: upload.item.name,
        },
    });
    const formData = new FormData();
    formData.append('acl', URLPresigned.data.presigned.bucketACL || 'private');
    formData.append('X-Amz-Credential', URLPresigned.data.presigned.bucketAccessKey);
    formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    formData.append('X-Amz-Date', URLPresigned.data.presigned.bucketDate);
    formData.append('key', URLPresigned.data.presigned.bucketKey);
    formData.append('Policy', URLPresigned.data.presigned.bucketPolicy);
    formData.append('X-Amz-Signature', URLPresigned.data.presigned.bucketSignature);
    formData.append('file', upload.item);
    yield makeRequest('POST', URLPresigned.data.presigned.bucketUrl, formData);
    const is_upload = yield API.uploadConfirm({
        urlParams: {
            token: URLPresigned.data.id,
        },
    });
    return is_upload;
});
export const formatAsset = (upload, scope, name) => {
    if (upload.length == 0)
        return [{ scope: scope, item: null, name: name }];
    if (upload[0].file) {
        return [{ scope: scope, item: upload[0].file, name: name, id: upload[0].id }];
    }
    else {
        return [];
    }
};
export const ReduxRequest = (action, request) => {
    return createAsyncThunk(action, (params_1, _a) => __awaiter(void 0, [params_1, _a], void 0, function* (params, { rejectWithValue }) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
        (_d = (_c = (_b = params === null || params === void 0 ? void 0 : params.options) === null || _b === void 0 ? void 0 : _b.redux) === null || _c === void 0 ? void 0 : _c.stateHandler) === null || _d === void 0 ? void 0 : _d.call(_c, (prevState) => {
            return Object.assign(Object.assign({}, prevState), { loading: false, error: undefined, editing: true });
        });
        const attachments = [];
        let assets = {};
        try {
            if (params === null || params === void 0 ? void 0 : params.uploads) {
                if (params.uploads.attachments) {
                    for (const upload of params.uploads.attachments) {
                        if (!upload.id) {
                            const is_upload = yield asyncUpload(upload);
                            attachments.push({ id: is_upload.data.id });
                        }
                        else {
                            attachments.push({ id: upload.id });
                        }
                    }
                }
                if (params.uploads.assets) {
                    for (const upload of params.uploads.assets) {
                        if (upload.item == null) {
                            assets[upload.name] = null;
                        }
                        else {
                            if (!upload.id) {
                                const is_upload = yield asyncUpload(upload);
                                assets[upload.name] = is_upload.data.id;
                            }
                        }
                    }
                }
            }
            if (attachments.length > 0)
                assets = Object.assign(Object.assign({}, assets), { attachments: attachments });
            if (((_e = params === null || params === void 0 ? void 0 : params.uploads) === null || _e === void 0 ? void 0 : _e.attachments) === null) {
                assets = Object.assign(Object.assign({}, assets), { attachments: null });
            }
            const result = (yield request(Object.assign(Object.assign({}, params), { bodyParams: Object.assign({ assets: ((_f = Object.keys(assets)) === null || _f === void 0 ? void 0 : _f.length) > 0 ? assets : undefined }, params === null || params === void 0 ? void 0 : params.bodyParams) })));
            if (result.error) {
                (_j = (_h = (_g = params === null || params === void 0 ? void 0 : params.options) === null || _g === void 0 ? void 0 : _g.redux) === null || _h === void 0 ? void 0 : _h.stateHandler) === null || _j === void 0 ? void 0 : _j.call(_h, (prevState) => {
                    return Object.assign(Object.assign({}, prevState), { loading: false, error: result.error.message, editing: false });
                });
                if ((_l = (_k = params === null || params === void 0 ? void 0 : params.options) === null || _k === void 0 ? void 0 : _k.alerts) === null || _l === void 0 ? void 0 : _l.error) {
                    void message.destroy();
                    void message.error({
                        content: params === null || params === void 0 ? void 0 : params.options.alerts.error,
                        style: {
                            marginTop: '90vh',
                        },
                    });
                }
                (_p = (_o = (_m = params === null || params === void 0 ? void 0 : params.options) === null || _m === void 0 ? void 0 : _m.then) === null || _o === void 0 ? void 0 : _o.error) === null || _p === void 0 ? void 0 : _p.call(_o, result.error, params);
                return rejectWithValue(result.error);
            }
            if ((_r = (_q = params === null || params === void 0 ? void 0 : params.options) === null || _q === void 0 ? void 0 : _q.redux) === null || _r === void 0 ? void 0 : _r.retrieveData) {
                (_u = (_t = (_s = params === null || params === void 0 ? void 0 : params.options) === null || _s === void 0 ? void 0 : _s.redux) === null || _t === void 0 ? void 0 : _t.stateHandler) === null || _u === void 0 ? void 0 : _u.call(_t, (prevState) => {
                    var _a, _b;
                    if ((_b = (_a = params === null || params === void 0 ? void 0 : params.options) === null || _a === void 0 ? void 0 : _a.redux) === null || _b === void 0 ? void 0 : _b.overrideData)
                        return { data: result.data, loading: false, editing: false };
                    return { data: Object.assign(Object.assign({}, prevState.data), result.data), loading: false, editing: false };
                });
            }
            else {
                (_x = (_w = (_v = params === null || params === void 0 ? void 0 : params.options) === null || _v === void 0 ? void 0 : _v.redux) === null || _w === void 0 ? void 0 : _w.stateHandler) === null || _x === void 0 ? void 0 : _x.call(_w, (prevState) => {
                    return Object.assign(Object.assign({}, prevState), { loading: false, editing: false });
                });
            }
            (_0 = (_z = (_y = params === null || params === void 0 ? void 0 : params.options) === null || _y === void 0 ? void 0 : _y.then) === null || _z === void 0 ? void 0 : _z.action) === null || _0 === void 0 ? void 0 : _0.call(_z, result, params);
            if ((_2 = (_1 = params === null || params === void 0 ? void 0 : params.options) === null || _1 === void 0 ? void 0 : _1.alerts) === null || _2 === void 0 ? void 0 : _2.success) {
                void message.destroy();
                void message.success({
                    content: params === null || params === void 0 ? void 0 : params.options.alerts.success,
                    style: {
                        marginTop: '8vh',
                    },
                });
            }
            return result;
        }
        catch (e) {
            const error = e;
            (_5 = (_4 = (_3 = params === null || params === void 0 ? void 0 : params.options) === null || _3 === void 0 ? void 0 : _3.then) === null || _4 === void 0 ? void 0 : _4.error) === null || _5 === void 0 ? void 0 : _5.call(_4, e, params);
            (_8 = (_7 = (_6 = params === null || params === void 0 ? void 0 : params.options) === null || _6 === void 0 ? void 0 : _6.redux) === null || _7 === void 0 ? void 0 : _7.stateHandler) === null || _8 === void 0 ? void 0 : _8.call(_7, (prevState) => {
                return Object.assign(Object.assign({}, prevState), { loading: false, error: error.error, editing: false });
            });
            if ((_10 = (_9 = params === null || params === void 0 ? void 0 : params.options) === null || _9 === void 0 ? void 0 : _9.alerts) === null || _10 === void 0 ? void 0 : _10.error) {
                void message.destroy();
                void message.error({
                    content: params === null || params === void 0 ? void 0 : params.options.alerts.error,
                    style: {
                        marginTop: '90vh',
                    },
                });
            }
            return rejectWithValue(e);
        }
    }));
};
